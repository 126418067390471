import { Box, Typography, useTheme } from '@mui/material'
import React from 'react';
import CommunityImage from "assets/svgs/about-us/involvement.svg";

const OurCommunity = () => {
    const theme = useTheme();
    return (
        <Box sx={{

            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
        }}>
            <Box>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: "2em",
                        marginBottom: "10px",
                        lineHeight: "45px",
                        fontWeight: "500",
                        [theme.breakpoints.down("md")]: {
                            fontSize: "1.8",
                        },
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "1.5",
                        },
                    }}
                >
                    Our  <span style={{ color: theme.palette.primary.dark }}>
                        Involvement
                    </span>
                </Typography>

                <Typography
                    variant="body1"
                    mb={1.5}
                    className="text"
                >
                    As part of Global Care Finder’s commitment in supporting local communities, we were overjoyed to be involved in a charity event at the Foundation Slum Child Care in Bangkok’s Chatujak District on 11th December 2015.

                    The Foundation is located in a slum area of the City and helps to take care of young children aged 3 months to 5 years old who come from underprivileged backgrounds. Many of these children are from poor families who cannot afford kindergarten care or are orphaned and living with an elderly grandparent. The Foundation provides a place for the children to be looked after while their parents are working and offers respite for carers.

                    We enjoyed a fun morning and as we joined in with the entertainment activities, as well as imparting some practical knowledge to the children on the right way to brush their teeth. We donated toothbrushes, toothpaste, books and pencils and are always proud to lend a hand and give something back to the community, wherever and whenever we can.
                </Typography>
            </Box>

            <Box component="img" src={CommunityImage}
                sx={{
                    maxWidth: "600px",
                    maxHeight: "500px",
                    [theme.breakpoints.down("lg")]: {
                        maxWidth: "500px",
                        maxHeight: "400px",
                    },
                    [theme.breakpoints.down("md")]: {
                        maxWidth: "300px",
                        maxHeight: "300px",
                        marginBottom: "10px"

                    },
                }}

            />
        </Box>
    )
}

export default OurCommunity