import { Box, Typography, useTheme } from '@mui/material'
import React from 'react';
import whyUseGCFImages from "assets/svgs/about-us/why-gcf.svg";

const WhyUseGCF = () => {
    const theme = useTheme();
    return (
        <Box>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                },
            }}>
                <Box>

                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: "2em",
                            marginBottom: "10px",
                            lineHeight: "45px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.8",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1.5",
                            },
                        }}
                    >
                        Why Use <span style={{ color: theme.palette.primary.dark }}>
                            Global Care Finder?
                        </span>

                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "1.5em",
                            marginBottom: "8px",
                            lineHeight: "30px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.2em",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1em",
                            },
                        }}
                    >No booking fees
                    </Typography>

                    <Typography
                        variant="body1"
                        mb={1.5}
                        className="text"
                    >
                        If you book an appointment at Global Care Finder we do not charge you a booking fee or 'convenience' fee!
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "1.5em",
                            marginBottom: "8px",
                            lineHeight: "30px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.2em",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1em",
                            },
                        }}
                    >
                        Save up to 70%
                    </Typography>

                    <Typography
                        variant="body1"
                        mb={1.5}
                        className="text"
                    >
                        Are you putting off medical care or have you received a shockingly expensive dental quote ? If so there is another option available: receive medical care overseas and save up to 70 % !

                        Quality doctor from around the globe can provide you the same level of medical care(if not better) for a fraction of the cost at home.
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "1.5em",
                            marginBottom: "8px",
                            lineHeight: "30px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.2em",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1em",
                            },
                        }}
                    >
                        Best Price Guarantee
                    </Typography>

                    <Typography
                        variant="body1"
                        mb={1.5}
                        className="text"
                    >
                        If you find a cheaper price for your dental work we will match it! We work with our global dental clinics to get you the best dental prices.Should you find your dental procedure, with the same doctor, with the same dates at a lower rate on the internet after you have booked, we'll match it. Just contact us within 24 hours of booking, with details of the website and we will match the price.
                    </Typography>

                </Box>

                <Box component="img" src={whyUseGCFImages}
                    sx={{
                        maxWidth: "500px",
                        maxHeight: "400px",
                        [theme.breakpoints.down("md")]: {
                            maxWidth: "300px",
                            maxHeight: "300px",
                            marginBottom: "10px"

                        },
                    }}

                />

            </Box>

            <Typography
                variant="h2"
                sx={{
                    fontSize: "1.5em",
                    marginBottom: "8px",
                    lineHeight: "30px",
                    fontWeight: "500",
                    [theme.breakpoints.down("md")]: {
                        fontSize: "1.2em",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "1em",
                    },
                }}
            >
                Maximum Selection
            </Typography>

            <Typography
                variant="body1"
                mb={1.5}
                className="text"
            >
                Browse 2000 + clinics in 13 countries.Save time by easily comparing clinics and doctors from around the globe!

                Compare clinics and doctors by viewing their photos, reviews, prices, education, years in business, free extras and certification.
            </Typography>

            <Typography
                variant="h2"
                sx={{
                    fontSize: "1.5em",
                    marginBottom: "8px",
                    lineHeight: "30px",
                    fontWeight: "500",
                    [theme.breakpoints.down("md")]: {
                        fontSize: "1.2em",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "1em",
                    },
                }}
            >
                Shop in your Currency
            </Typography>

            <Typography
                variant="body1"
                mb={1.5}
                className="text"
            >
                Explore the cost of dental treatment around the globe in your home currency.We know there is no better way to figure out how much you can save than viewing prices in your home currency.

                Global Care Finder supports the Euro, Pound Sterling, US dollar, Canadian Dollar, Australian dollar, New Zealand dollar, Mexican peso, Thai baht, Norwegian krone, Danish krone, Philippine peso, Malaysian ringgit, Singapore dollar, Chinese yuan, Vietnamese dong, Hong Kong dollar, Costa Rican colon, Indonesian rupiah, and Colombian peso currencies.
            </Typography>

            <Typography
                variant="h2"
                sx={{
                    fontSize: "1.5em",
                    marginBottom: "8px",
                    lineHeight: "30px",
                    fontWeight: "500",
                    [theme.breakpoints.down("md")]: {
                        fontSize: "1.2em",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "1em",
                    },
                }}
            >
                Risk Free Booking - No Cancellation Fees
            </Typography>

            <Typography
                variant="body1"
                mb={1.5}
                className="text"
            >
                Plans change and we understand that you may want to spend your time at the beach rather than at the doctor! Global Care Finder will never charge you a change / cancel fee.

                You can easily change / cancel your appointment at any time with Global Care Finder by visiting https://globalcarefinder.com/ and clicking on 'Login'. Please note, you may still be subject to change and cancellation fees that the dental clinic itself may impose. So go ahead, be free to change your mind!
            </Typography>
        </Box >
    )
}

export default WhyUseGCF