import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import "assets/scss/style.scss";
import { RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import themes from "./components/themes";
import router from "./routes";
import TawkMessenger from "components/tawkMessenger/TawkMessenger";


function App() {
  const customization = 5;
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <RouterProvider router={router} />
          <TawkMessenger />
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;