import IndiaFlag from "assets/images/countries/india.png";
import CanadaFlag from "assets/images/countries/canada.png";
import JapanFlag from "assets/images/countries/japan.png";
import TurkeyFlag from "assets/images/countries/turkey.png";
import UnitedKingdomFlag from "assets/images/countries/united-kingdom.png";
import BrazilFlag from "assets/images/countries/brazil.png";
import SingaporeFlag from "assets/images/countries/singapore.png";
import CostRicaFlag from "assets/images/countries/costa-rica.png";
import SouthKoreaFlag from "assets/images/countries/south-korea.png";
import MexicoFlag from "assets/images/countries/mexico.png";
import ThailandFlag from "assets/images/countries/thailand.png";
import SpainFlag from "assets/images/countries/spain.png";
import MalaysiaFlag from "assets/images/countries/malaysia.png";
import GermanyFlag from "assets/images/countries/germany.png";
import TaiwanFlag from "assets/images/countries/taiwan.png";
import UnitedStatesFlag from "assets/images/countries/united-states.png";
import CZechRepublicFlag from "assets/images/countries/czech-republic.png";
import ItalyFlag from "assets/images/countries/italy.png";
import FranceFlag from "assets/images/countries/france.png";
import ColombiaFlag from "assets/images/countries/colombia.png";
import HungaryFlag from "assets/images/countries/hungary.png";
import SwedenFlag from "assets/images/countries/sweden.png";
import AustraliaFlag from "assets/images/countries/australia.png";
import DominicanRepublicFlag from "assets/images/countries/dominican-republic.png";
import ArgentinaFlag from "assets/images/countries/argentina.png";
import OmanFlag from "assets/images/countries/oman.png";
import PanamaFlag from "assets/images/countries/panama.png";
import UnitedArabEmiratesFlag from "assets/images/countries/united-arab-emirates.png";

export const specificCountries = [
  { key: "india", label: "India", countryCode: "IN", image: IndiaFlag },
  { key: "turkey", label: "Turkey", countryCode: "TR", image: TurkeyFlag },
  { key: "japan", label: "Japan", countryCode: "JP", image: JapanFlag },
  { key: "canada", label: "Canada", countryCode: "CA", image: CanadaFlag },
  { key: "united-kingdom", label: "United Kingdom", countryCode: "GB", image: UnitedKingdomFlag },
  { key: "brazil", label: "Brazil", countryCode: "BR", image: BrazilFlag },
  { key: "singapore", label: "Singapore", countryCode: "SG", image: SingaporeFlag },
  { key: "costa-rica", label: "Costa Rica", countryCode: "CR", image: CostRicaFlag },
  { key: "south-korea", label: "South Korea", countryCode: "KR", image: SouthKoreaFlag },
  { key: "mexico", label: "Mexico", countryCode: "MX", image: MexicoFlag },
  { key: "thailand", label: "Thailand", countryCode: "TH", image: ThailandFlag },
  { key: "spain", label: "Spain", countryCode: "ES", image: SpainFlag },
  { key: "malaysia", label: "Malaysia", countryCode: "MY", image: MalaysiaFlag },
  { key: "germany", label: "Germany", countryCode: "DE", image: GermanyFlag },
  { key: "taiwan", label: "Taiwan", countryCode: "TW", image: TaiwanFlag },
  { key: "united-states", label: "United States", countryCode: "US", image: UnitedStatesFlag },
  { key: "united-arab-emirates", label: "United Arab Emirates", countryCode: "AE", image: UnitedArabEmiratesFlag },
  { key: "czech-republic", label: "Czech Republic", countryCode: "CZ", image: CZechRepublicFlag },
  { key: "italy", label: "Italy", countryCode: "IT", image: ItalyFlag },
  { key: "france", label: "France", countryCode: "FR", image: FranceFlag },
  { key: "colombia", label: "Colombia", countryCode: "CO", image: ColombiaFlag },
  { key: "hungary", label: "Hungary", countryCode: "HU", image: HungaryFlag },
  { key: "sweden", label: "Sweden", countryCode: "SE", image: SwedenFlag },
  { key: "australia", label: "Australia", countryCode: "AU", image: AustraliaFlag },
  { key: "dominican-republic", label: "Dominican Republic", countryCode: "DO", image: DominicanRepublicFlag },
  { key: "argentina", label: "Argentina", countryCode: "AR", image: ArgentinaFlag },
  { key: "oman", label: "Oman", countryCode: "OM", image: OmanFlag },
  { key: "panama", label: "Panama", countryCode: "PA", image: PanamaFlag },
];
