import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { specificCountries } from "constants/countries";
import topSearchServices from "constants/top-services";
import topSearchSpecialties from "constants/top-specialties";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "hooks/useApi";
import { useEffect } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import DoctorService from "services/DoctorService";
import { getResponse } from "utils/get-response";
import { useDispatch } from "react-redux";
import { fetchFilteredDoctorList } from "store/doctors";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BrowseMoreModal = ({ exploreMoreHandler }) => {
  const [value, setValue] = useState("1");
  const navigate = useNavigate();
  const [specialtyName, setSpecialtyName] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [specialtyId, setSpecialtyId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();

  const getSpecialtiesByNameApi = useApi(DoctorService.getSpecialtiesByName);
  const getServiceByNameApi = useApi(DoctorService.getServiceByName);

  const handleChange = (event, newValue) => {

    setValue(newValue);
  };

  const handleClose = () => {
    exploreMoreHandler();
  };

  const fetchSpecialtiesByName = async (speciality) => {
    let response = await getSpecialtiesByNameApi.request(speciality);
    response = getResponse(response);

    setSpecialtyId(response?.id);
    setSpecialtyName(response?.name);
  };

  const fetchServiceByName = async (service) => {
    let response = await getServiceByNameApi.request(service);
    response = getResponse(response);
    setServiceId(response?.id);
    setServiceName(response?.name);
  };

  const selectHandler = (e) => {
    if (e?.target?.value && e?.target?.name) {
      setSelectedCountry(e.target.value);
      fetchSpecialtiesByName(e?.target?.name);
    } else {
      toast.warning("Please select country and specialty");
    }
  };

  useEffect(() => {
    if (!selectedCountry || !specialtyId || !specialtyName) return;
    dispatch(
      fetchFilteredDoctorList(`/users/get-relevent-doctor?specialities=${specialtyId}&country=${selectedCountry}`)
    );
    navigate(`/specialities/${specialtyName}/countries/${selectedCountry}`);
    exploreMoreHandler();
  }, [specialtyId, specialtyName]);

  useEffect(() => {
    if (!serviceName || !serviceId) return;
    dispatch(
      fetchFilteredDoctorList(`/users/get-relevent-doctor?services=${serviceId}`)
    );
    navigate(`/services/${serviceName}`);
    exploreMoreHandler();
  }, [serviceId, serviceName]);

  const onServiceHandler = (e) => {
    if (e?.target?.innerHTML) {
      fetchServiceByName(e?.target?.innerHTML);
    } else {
      toast.warning("Please select a specialty");
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <Box pb={3} sx={{
        backgroundColor: theme.palette.grey[150]
      }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{
        backgroundColor: theme.palette.grey[150]
      }}>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            height: "400px",
          }}
        >
          <TabContext value={value} width="100%">

            <TabPanel value="1" width="100%" sx={{
              "& .Mui-selected": {
                color: "white !important"
              }
            }}>
              <Box sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "left",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                  rowGap: 2,
                  borderBottom: 0
                }

              }}>

                <Typography variant="h2" sx={{ textWrap: "nowrap" }}>
                  Browse top{" "}
                  <span style={{ color: theme.palette.primary.dark, textTransform: "capitalize" }}>
                    specialties
                  </span>{" "}
                </Typography>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Specialties" value="1"
                    sx={{
                      borderRadius: "10px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      color: theme.palette.background.paper,
                      marginRight: "1em",
                      backgroundColor: value === "1" ? theme.palette.primary.main : theme.palette.primary.dark,
                      "&:hover": {
                        backgroundColor: value === "1" ? theme.palette.primary.dark : theme.palette.primary.main,
                        color: theme.palette.background.paper
                      }
                    }}
                  />
                  <Tab label="Services" value="2"
                    sx={{
                      borderRadius: "10px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      color: theme.palette.background.paper,
                      backgroundColor: value === "2" ? theme.palette.primary.main : theme.palette.primary.dark,
                      "&:hover": {
                        backgroundColor: value === "2" ? theme.palette.primary.dark : theme.palette.primary.main,
                        color: theme.palette.background.paper,
                      }
                    }}
                  />
                </TabList>
              </Box>

              {topSearchSpecialties?.map((specialty) => (
                <>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 200, [theme.breakpoints.down("md")]: { width: "100%" } }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      {specialty?.label}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={selectHandler}
                      label="select-specialty-by-country"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250,
                          },
                        },
                      }}
                      name={specialty.label}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {specificCountries.map((country) => (
                        <MenuItem key={country.key} value={country.key}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ))}
            </TabPanel>
            <TabPanel value="2" width="100%" sx={{
              "& .Mui-selected": {
                color: "white !important"
              }
            }}>
              <Box sx={{
                borderBottom: 1, borderColor: "divider", width: "100%", display: "flex",
                justifyContent: "space-between",
                alignItems: "left",
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                  rowGap: 2,
                  borderBottom: 0
                }
              }}>
                <Typography variant="h2" sx={{ textWrap: "nowrap" }}>
                  Browse top{" "}
                  <span style={{ color: theme.palette.primary.dark, textTransform: "capitalize" }}>
                    services
                  </span>{" "}
                </Typography>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Specialties" value="1"
                    sx={{
                      borderRadius: "10px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      color: theme.palette.background.paper,
                      marginRight: "1em",
                      backgroundColor: value === "1" ? theme.palette.primary.main : theme.palette.primary.dark,
                      "&:hover": {
                        backgroundColor: value === "1" ? theme.palette.primary.dark : theme.palette.primary.main,
                        color: theme.palette.background.paper
                      }
                    }} />
                  <Tab label="Services" value="2"
                    sx={{
                      borderRadius: "10px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      color: theme.palette.background.paper,
                      backgroundColor: value === "2" ? theme.palette.primary.main : theme.palette.primary.dark,
                      "&:hover": {
                        backgroundColor: value === "2" ? theme.palette.primary.dark : theme.palette.primary.main,
                        color: theme.palette.background.paper
                      }
                    }}
                  />
                </TabList>
              </Box>

              <Grid container spacing={2} mt={1}>
                {topSearchServices?.map((service) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={service?.slug}>
                    <Link underline="hover" sx={{ cursor: "pointer" }}>
                      <p onClick={onServiceHandler} key={service?.label}>
                        {service?.label}
                      </p>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
    </BootstrapDialog >
  );
};

export default BrowseMoreModal;
