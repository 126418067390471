import React, { useState } from 'react'
import { specificCountries } from 'constants/countries';
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button, useTheme } from '@mui/material';
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DoctorsByLocation from 'components/ui/DoctorsByLocation/DoctorsByLocation';

const Locations = () => {
    const theme = useTheme()
    const navigate = useNavigate();
    const { locationId } = useParams();
    const [countries, setCountries] = useState(specificCountries);
    const handleCountries = (country) => {
        if (country) {
            navigate(`/locations/${country}`);
        } else {
            toast.error("Please select a country");
        }
    };

    return locationId ? <DoctorsByLocation /> : (
        <Box className="mainCountriesBody" sx={{
            padding: { lg: "2em 1em", xs: "1em" }
        }}>
            <Typography sx={{
                fontSize: "2em",
                marginBottom: "10px",
                lineHeight: "45px",
                fontWeight: "500",
                [theme.breakpoints.down("md")]: {
                    fontSize: "1.8",
                },
                [theme.breakpoints.down("sm")]: {
                    fontSize: "1.5",
                },
            }} variant='h1'>All Doctors  <span style={{ color: theme.palette.primary.dark }}>
                    Location
                </span>
            </Typography>

            <Box sx={{ display: "flex", gap: "20px", justifyContent: "center", flexWrap: "wrap" }}>
                {
                    countries.map((country, index) => (
                        <Button onClick={() => {
                            handleCountries(country.label)
                        }} key={country.key} sx={{ width: "20%", display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                            {country?.image && <Box className='countriesFlags' component="img" src={country?.image} />}
                            <Typography variant='h2' className='secondaryHeadingTypography'>
                                {country.label}
                            </Typography>
                        </Button>
                    ))
                }
            </Box>
        </Box>
    )
}

export default Locations