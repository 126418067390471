import React from "react";
import MainLayout from "components/layout/MainLayout";
import Loadable from "components/ui/Loadable";
import { ROUTES } from "constants";
import PrivateRouteGuard from "guards/PrivateRouteGuard";
import { lazy } from "react";
import ThankYou from "views/private/thank-you/ThankYou";

const PatientAppointment = Loadable(
  lazy(() => import("views/private/patient-appointment"))
);
const PatientProfile = Loadable(
  lazy(() => import("views/private/patient-profile"))
);
const ChatPage = Loadable(lazy(() => import("views/private/chat-page")));

const PrivateRoutes = {
  path: "/",
  element: (
    <PrivateRouteGuard>
      <MainLayout />
    </PrivateRouteGuard>
  ),
  children: [
    {
      path: ROUTES.PatientAppointment,
      element: <PatientAppointment />,
    },
    {
      path: ROUTES.PatientProfile,
      element: <PatientProfile />,
    },
    {
      path: "/chat/:appointmentId",
      element: <ChatPage />,
    },
  ],
};

export default PrivateRoutes;
