import {
  Box,
  Typography,
  useTheme,
  Link as MuiLink,
  styled,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState, useEffect, useMemo } from "react";
import useApi from "hooks/useApi";
import { toast } from "react-toastify";
import DoctorService from "services/DoctorService";
import { getResponse } from "utils/get-response";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchFilteredDoctorList } from "store/doctors";
import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  margin: "1em 0em",
}));

const StyledTypographyMobile = styled(Typography)(({ theme }) => ({
  margin: "1em 0em",
}));

const Footer = () => {
  const theme = useTheme();
  const [featureSpecialities, setFeatureSpecialities] = useState([]);
  const featureSpecialitiesApi = useApi(DoctorService.featureSpecialities);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTopSpecialities = (data, section) => {
    if (section === "Top Specialities") {
      dispatch(
        fetchFilteredDoctorList(
          `/users/get-relevent-doctor?specialities=${data.id}`
        )
      );
      navigate(``);
    }
  };

  const fetchFeatureSpecialities = async () => {
    let response = await featureSpecialitiesApi.request();
    response = getResponse(response);
    if (response && response?.length) {
      setFeatureSpecialities(
        response.map((speciality) => ({
          id: speciality.id,
          text: speciality.name,
        }))
      );
    } else {
      toast.info("No feature specialty exist");
    }
  };

  useEffect(() => {
    fetchFeatureSpecialities();
  }, []);

  const footerLinks = useMemo(() => {
    return {
      "Global Care Finder": [
        { label: "Home", href: "#" },
        { label: "About Us", href: "/about-us" },
        { label: "Contact Us", href: "/contact-us" },
        { label: "Doctors", href: "/doctor-search" },
      ],
      Discover: [
        { label: "Locations", href: "/locations" },
        { label: "Sitemaps", href: "/sitemap" },
      ],
      "Top Specialities": featureSpecialities || [],
      "Connect with Us": [
        { label: "linkedin", href: "#", image: <LinkedIn /> },
        {
          label: "instagram",
          href: "https://www.instagram.com/globalcarefinder/",
          image: <Instagram />,
        },
        {
          label: "facebook",
          href: "https://www.facebook.com/people/Global-Care-Finder/61567352351701/",
          image: <Facebook />,
        },
      ],
    };
  }, [featureSpecialities]);

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= theme.breakpoints.values.sm
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= theme.breakpoints.values.sm);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={{ backgroundColor: theme.palette.primary.main, px: { xs: 0, md: 2 } }}
    >
      <Box
        sx={{
          maxWidth: "1400px",
          margin: "auto",
          padding: "3em 1em",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "center" : "space-between",
          gap: "10px",
        }}
      >
        {Object.keys(footerLinks).map((section, idx) => (
          <Box key={idx} sx={{ width: isMobile ? "100%" : "auto" }}>
            {isMobile ? (
              <Accordion sx={{ mx: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <StyledTypographyMobile variant="h5">
                    {section}
                  </StyledTypographyMobile>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {footerLinks[section].length > 0 ? (
                      footerLinks[section].map((data, index) => (
                        <ListItem
                          key={index}
                          onClick={() => handleTopSpecialities(data, section)}
                        >
                          {data.image ? (
                            <MuiLink
                              href={data.href}
                              target="_blank"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {data.image}
                              <Typography>
                                {section !== "Top Specialities"
                                  ? data.label
                                  : data.text}
                              </Typography>
                            </MuiLink>
                          ) : (
                            <MuiLink href={data.href}>
                              {section !== "Top Specialities"
                                ? data.label
                                : data.text}
                            </MuiLink>
                          )}
                        </ListItem>
                      ))
                    ) : (
                      <Typography>No data found</Typography>
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Box>
                <StyledTypography variant="h4">{section}</StyledTypography>
                <List>
                  {footerLinks[section].length > 0 ? (
                    footerLinks[section].map((data, index) => (
                      <ListItem
                        key={index}
                        sx={{ px: 0 }}
                        onClick={() =>
                          section === "Top Specialities" &&
                          handleTopSpecialities(data)
                        }
                      >
                        {data.image ? (
                          <MuiLink
                            href={data.href}
                            target="_blank"
                            sx={{
                              color: theme.palette.background.paper,
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {data.image}
                            <Typography>
                              {section !== "Top Specialities"
                                ? data.label
                                : data.text}
                            </Typography>
                          </MuiLink>
                        ) : (
                          <MuiLink
                            href={data.href}
                            sx={{
                              color: theme.palette.background.paper,
                            }}
                          >
                            {section !== "Top Specialities"
                              ? data.label
                              : data.text}
                          </MuiLink>
                        )}
                      </ListItem>
                    ))
                  ) : (
                    <Typography sx={{ color: "#FFF" }}>
                      No data found
                    </Typography>
                  )}
                </List>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;