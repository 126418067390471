const { Box, Typography, useTheme, Divider } = require("@mui/material");
const { Link } = require("react-router-dom");

export const CopyRights = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        textAlign: "center",
        background: theme.palette.primary.main,
        p: 2,
      }}
    >
      <Divider />
      <Typography
        variant="body2"
        gutterBottom
        sx={{ color: theme.palette.background.paper, pt: 1 }}
      >
        © {new Date().getFullYear()} Global Care Finder
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2, // Space between links
          mt: 1, // Margin top for spacing
        }}
      >
        {[
          { to: "/terms-condition", label: "Terms & Condition" },
          { to: "/privacy-policy", label: "Privacy Policy" },
        ].map((link, index) => (
          <Typography
            key={index}
            variant="body2"
            component={Link}
            to={link.to}
            sx={{
              color: "white",
              fontWeight: "bold",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)",
              p: "4px 8px", // Padding
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              borderRadius: 1, // Slightly rounded corners
              textDecoration: "none",
              "&:hover": {
                color: "#FFD700",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
              },
            }}
          >
            {link.label}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
