import React, { useState } from 'react'
import { Box, Button, CircularProgress, FormControl, FormHelperText, TextField, Typography, useTheme } from '@mui/material';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import useApi from 'hooks/useApi';
import UserService from "services/UserService";

import { toast } from "react-toastify";
import MuiPhone from '../MuiPhone/MuiPhone';

const ContactForm = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false)
    const postContactUsDataApi = useApi(UserService.postContactUsData);

    const formFields = [
        { label: "Name", type: "text", isRequired: true },
        { label: "Email", type: "email", isRequired: true },
        { label: "Phone", type: "text", isRequired: false },
        { label: "Message", type: "textarea", isRequired: true },
    ];
    const generateInitialValues = (fields) => {
        const initialValues = {};

        fields.forEach((field) => {
            initialValues[field.label.toLowerCase()] = '';
        });

        return initialValues;
    }

    const generateValidationSchema = (fields) => {
        const shape = {};
        fields.forEach((field) => {
            let validation = Yup.string().trim();

            if (field.type === "email") {
                validation = Yup.string().email("Invalid email format");
            }

            if (field.isRequired) {
                validation = validation.required(`${field.label} is required`);
            }

            if (field.type === "textarea") {
                validation = validation.max(800, `${field.label} cannot be greater than 800 characters`);
            }
            if (field.label === "Name") {
                validation = validation.max(50, `${field.label} cannot be greater than 50 characters`);
            }

            shape[field.label.toLowerCase()] = validation;
        });

        return Yup.object().shape(shape);
    };



    const formik = useFormik({
        initialValues: generateInitialValues(formFields),
        validationSchema: generateValidationSchema(formFields),
        onSubmit: async (values, { setErrors, setSubmittin, resetForm }) => {
            setLoading(true)
            const data = await postContactUsDataApi.request(values);

            if (data.status === 201 || data.status === 200) {
                setLoading(false)
                toast.success("Thank you! Your contact request has been submitted. We will contact you soon.");
                resetForm();
            }
        }
    });


    return (
        <Box className="contactBody" sx={{
            padding: { lg: "2em 1em", xs: "1em" }
        }}>
            <Typography
                variant="h1"
                sx={{
                    fontSize: "2em",
                    marginBottom: "10px",
                    lineHeight: "45px",
                    fontWeight: "500",
                    [theme.breakpoints.down("md")]: {
                        fontSize: "1.8",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "1.5",
                    },
                }}
            >
                Contact Us
            </Typography>
            <form className='contactUsForm' onSubmit={formik.handleSubmit} style={{ padding: { lg: '20px', xs: 0 } }}>
                {formFields.map((field, index) => (

                    <FormControl
                        fullWidth
                        error={Boolean(formik.touched[field.label.toLowerCase()] && formik.errors[field.label.toLowerCase()])}
                        sx={{ marginBottom: "10px" }}
                    >
                        {
                            field.label === "Phone"
                                ?
                                <MuiPhone
                                    value={formik.values[field.label.toLowerCase()]}
                                    onChange={formik.handleChange}
                                    name={"phone"}
                                    setFieldValue={formik.setFieldValue}
                                />
                                : <TextField
                                    key={index}
                                    id={field.label.toLowerCase()}
                                    name={field.label.toLowerCase()}
                                    label={field.isRequired ? `${field.label} *` : field.label}
                                    type={field.type}
                                    value={formik.values[field.label.toLowerCase()]}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    multiline={field.type === "textarea" && true}
                                    rows={field.type === "textarea" && 4}
                                    error={formik.touched[field.label.toLowerCase()] && formik.errors[field.label.toLowerCase()]}
                                />
                        }

                        {formik.touched[field.label.toLowerCase()] && formik.errors[field.label.toLowerCase()] && (
                            <FormHelperText
                                error
                                sx={{ fontSize: 13 }}
                                id={`standard-weight-helper-text-${field.label.toLowerCase()}-script`}
                            >
                                {formik.errors[field.label.toLowerCase()]}
                            </FormHelperText>
                        )}
                    </FormControl>
                ))}
                <Button variant='contained' type="submit">
                    {loading
                        ? <CircularProgress sx={{ color: "#fff" }} size="25px" />
                        : "Submit"}
                </Button>
            </form>

        </Box>
    )
}

export default ContactForm