import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { CopyRights } from "components/ui/CopyRight/CopyRight";
import AnimatedBox from "components/ui/AnimatedBox/AnimatedBox";
import NavigationScroll from "../NavigationScroll";

const MainLayout = () => {


  return (
    <NavigationScroll>

      <Box className="home-page">
        <Header />
        <Box component="section" className="main-body" flex="1" position={"relative "} minHeight="100vh"
        >
          <Outlet />
        </Box>

        <AnimatedBox>
          <Footer />
        </AnimatedBox>

        <CopyRights />
      </Box>
    </NavigationScroll>
  );
};

export default MainLayout;
