import React from 'react'
import { Box, Typography, List, ListItem, ListItemText, useTheme } from '@mui/material';
import HowItWorksImage from "assets/svgs/about-us/how-it-works.svg";

const HowItWorks = () => {
    const theme = useTheme();


    const appointmentList = [
        "Appointment date",
        "Clinic name",
        "Clinic phone number",
        "Clinic e-mail address",
        "Clinic map",
        "Medical procedures requested"
    ];

    const companyProgress = [
        "High resolution photos of the clinic and staff",
        "Doctor education and experience",
        "Price list",
        "The years the clinic has been in business",
        "Payment methods accepted",
        "Hours of operation",
        "Medical clinic certifications",
        "Medical extras (free WiFi, free consultation, free transportation, free hotel stay etc.)"
    ]


    return (
        <Box>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                },
            }}>
                <Box>
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: "2em",
                            marginBottom: "10px",
                            lineHeight: "45px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.8",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1.5",
                            },
                        }}
                    >
                        Useful and <span style={{ color: theme.palette.primary.dark }}>
                            Trustable
                        </span>

                    </Typography>

                    <Typography
                        variant="body1"
                        mb={1.5}
                        className="text"
                    >
                        Are you putting off medical care or have you received a shockingly expensive quote? If so there is another option available: receive dental care overseas and save up to 70%.
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "1.5em",
                            marginBottom: "8px",
                            lineHeight: "30px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.2em",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1em",
                            },
                        }}
                    >
                        Here is how it works:
                    </Typography>

                    <Typography
                        variant="body1"
                        mb={1.5}
                        className="text"
                    >
                        Visit https://globalcarefinder.com/ and search 2000+ doctor in 13 countries around the globe. You can easily compare clinics and review all the details you need to know about the clinic:
                    </Typography>

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {companyProgress.map((label, index) => (
                            <ListItem sx={{ listStyleType: 'disc', paddingY: 0, marginLeft: "20px" }} key={index}>
                                <ListItemText sx={{ display: 'list-item' }} primary={label} />
                            </ListItem>
                        ))
                        }
                    </List>
                </Box>

                <Box component="img" src={HowItWorksImage}
                    sx={{
                        maxWidth: "600px",
                        maxHeight: "500px",
                        [theme.breakpoints.down("md")]: {
                            maxWidth: "300px",
                            maxHeight: "300px",
                            marginBottom: "10px"
                        },
                    }}

                />

            </Box>


            <Typography
                variant="body1"
                mb={1.5}
                className="text"
            >
                You've found the perfect medical clinic overseas and now it is time to make an appointment.

                Click the big 'Book Now' button and enter the details of your appointment: the date you would like to receive treatment and the dental work you need.

                When you click the big 'Confirm' button you have just confirmed your appointment. You will receive a confirmation e-mail providing you with all the details you need for your appointment:
            </Typography>

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {appointmentList.map((label, index) => (
                    <ListItem sx={{ listStyleType: 'disc', paddingY: 0, marginLeft: "20px" }} key={index}>
                        <ListItemText sx={{ display: 'list-item' }} primary={label} />
                    </ListItem>
                ))}
            </List>

            <Typography
                variant="body1"
                mb={1.5}
                className="text"
            >
                You will receive a follow-up e-mail or phone call directly from the dental clinic about your appointment. At this time the dental clinic may request further information about your medical health (past history, X-rays, photos) that you can choose to send to the clinic prior to your appointment.

                You can easily change/cancel your appointment at any time with Global Care Finder by visiting https://globalcarefinder.com/ and clicking on 'Login'. Global Care Finder does not charge a change/cancel fee. Please note, you may still be subject to change and cancellation fees that the dental clinic itself may impose. So go ahead, be free to change your mind.

                Some dental clinics may require an initial deposit. These deposits terms will clearly be displayed in your confirmation e-mail and they can also be found on the clinics' listing under 'about clinic' and 'deposit terms'.

                Now it is time to travel to your medical clinic for treatment! Print out your confirmation e-mail and bring it with you when you check-in at the dental clinic on the date/time agreed upon with the clinic. When you step through the clinic's doors you will be pleasantly surprised by the quality, cleanliness and modern equipment.

                The doctor will first conduct an oral exam to determine your treatment plan. What work you think you need done on your teeth may in fact be different than what your doctor recommends. Once you have agreed with the treatment plan provided by your doctor lay back and enjoy!

                You've completed your treatment and now you can smile with confidence!

                You will pay the dental clinic directly for your medical treatment: you do not pay Global Care Finder . You can pay for your medical treatment using payment methods (i.e. cash, credit card, PayPal etc.) accepted by the clinic which you can review in the 'about clinic' 'acceptable payment methods' area.

                Please note, that you will pay your clinic in the local currency of the country where the clinic is located. Your credit card company may charge you a foreign exchange fee to complete the transaction in the local currency. In addition, we advise that you contact your credit card company ahead of your appointment to inform them of your medical payment because they may decline the transaction.
            </Typography>
        </Box>
    )
}

export default HowItWorks