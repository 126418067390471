import { Box } from '@mui/material'
import ContactForm from 'components/ui/ContactForm/ContactForm'
import React from 'react'


const ContactUs = () => {
    return (
        <Box>
            <ContactForm />
        </Box>
    )
}

export default ContactUs