import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AuthenticationRoutes from "./AuthenticatedRoutes";
import MainRoutes from "./MainRoutes";
import PrivateRoutes from "./PrivateRoutes";

const router = createBrowserRouter(
  [MainRoutes, AuthenticationRoutes, PrivateRoutes,],
  {
    basename: "/",
  }
);

export default router;
