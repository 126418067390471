import React from "react";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { IconLogout, IconSettings } from "@tabler/icons-react";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MainCard from "components/ui/cards/MainCard";
import Transitions from "components/ui/extended/Transitions";
import { ROLE, ROUTES } from "constants";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "store/users";
import BrowseMoreModal from "../BrowseMoreModal";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';

const ProfileSection = ({ navLinks }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = 5;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [browseMoreOpen, setBrowseMoreOpen] = useState(false);
  const loggedUser = useSelector((state) => state?.entities?.users?.loggedUser);


  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [current, setCurrent] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showHeaderSearch, setShowHeaderSearch] = useState(false);


  const handleOpen = (value) => {
    setOpen(true);
    setValue(0);
    setCurrent(value);
  };

  const currentUrl = location.pathname;

  const handleLogout = async () => {
    dispatch(logoutUser());
    navigate("/");
  };

  const handleProfile = async () => {
    navigate("/manage-profile");
    handleToggle();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenModal(false);
    setOpen(false);
    setCurrent("");
  };

  const handleToggle = () => {
    setOpenModal((prevState) => !prevState);
  };

  const handleMyAppointments = () => {
    handleToggle();
    navigate(ROUTES.PatientAppointment);
  };

  const signUpHandler = (user) => {
    handleToggle();
    if (user === ROLE.Doctor) {
      window.location.href = `${process.env.REACT_APP_ADMIN_PANEL}${ROUTES.SignIn}`;
    } else {
      navigate(ROUTES.SignIn);
    }
  };


  const exploreMoreHandler = () => {
    handleToggle();
    setBrowseMoreOpen((prevState) => !prevState);
  };


  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.main,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.secondary.main,
            background: `${theme.palette.secondary.main}!important`,
            color: theme.palette.secondary.light,
            "& svg": {
              stroke: theme.palette.secondary.light,
            },
            "& .MuiAvatar-root": {
              bgcolor: theme.palette.primary.dark,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          loggedUser?.user?.avatar ? (
            <Avatar
              src={loggedUser?.user?.avatar}
              sx={{
                ...theme.typography.mediumAvatar,
                margin: "8px 0 8px 8px !important",
                cursor: "pointer",
              }}
              ref={anchorRef}
              aria-controls={openModal ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: theme.palette.secondary.dark,
                fontSize: "1.3em",
                color: "#fff !important",
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: theme.palette.primary.main,
                },
              }}
            >
              {loggedUser?.user?.firstName[0]}
            </Avatar>
          )
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color="white" />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={openModal ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={openModal}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: "1500" }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={openModal} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box
                    sx={{ p: 2, pt: 0, maxHeight: "250px", overflowY: "auto" }}
                  >
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      <ListItemButton sx={{
                        [theme.breakpoints.up("md")]: {
                          display: "none",
                        },
                      }}>
                        {!["/sign-up", "/sign-in"].includes(currentUrl) ? (
                          <Typography
                            onClick={exploreMoreHandler}
                            variant="body2"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              "&:hover": {
                                cursor: "pointer"
                              },
                              borderRadius: `${customization.borderRadius}px`,
                              width: "100%",
                              [theme.breakpoints.down("sm")]: {
                                width: "100%",
                                borderRadius: "10px",
                              },
                            }}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          >


                            <ListItemIcon>
                              <TravelExploreOutlinedIcon stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Browse More
                                </Typography>
                              }
                            />
                            {!browseMoreOpen && (
                              <ExpandMoreIcon color="background.paper" />
                            )}
                            {browseMoreOpen && (
                              <ExpandLessIcon color="background.paper" />
                            )}
                          </Typography>
                        ) : (
                          <></>
                        )}</ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                          width: "100%",
                        }}
                        selected={selectedIndex === 4}
                        onClick={handleProfile}
                      >
                        <ListItemIcon>
                          <ManageAccountsOutlinedIcon
                            stroke={1.5}
                            size="1.3rem"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">
                              Manage Profile
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                          width: "100%",
                        }}
                        selected={selectedIndex === 4}
                        onClick={handleMyAppointments}
                      >
                        <ListItemIcon>
                          <TodayOutlinedIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">
                              My Appointments
                            </Typography>
                          }
                        />
                      </ListItemButton>

                      {navLinks.map((link, index) => {
                        const isActive = location.pathname === link.route;
                        return (

                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                              width: "100%",
                              display: { xs: "flex", md: "none" },
                            }}
                            key={index}
                            onClick={() => navigate(link.route)}
                          >
                            <ListItemIcon>
                              {React.cloneElement(link?.icon, {
                                stroke: 1.5,
                                size: "1.3rem",
                              })}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  {link.name}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        );
                      })}


                      {loggedUser && (
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                            width: "100%",
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <LogoutOutlinedIcon stroke={1.5}
                              size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      )}

                      {!loggedUser && (
                        <>
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                              width: "100%",
                            }}
                            selected={selectedIndex === 4}
                            onClick={() => signUpHandler(ROLE.Doctor)}
                          >
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Login as Doctor
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                              width: "100%",
                            }}
                            selected={selectedIndex === 4}
                            onClick={() => signUpHandler(ROLE.Patient)}
                          >
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Login as Patient
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </>
                      )}
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      {
        browseMoreOpen && (
          <BrowseMoreModal exploreMoreHandler={exploreMoreHandler} />
        )
      }
    </>
  );
};

export default ProfileSection;
