import { Box, Typography, useTheme } from '@mui/material'
import React from 'react';
import HelpImage from "assets/svgs/about-us/help.svg";

const HowWeHelp = () => {
    const theme = useTheme()
    return (
        <Box>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                },
            }}>
                <Box>
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: "2em",
                            marginBottom: "10px",
                            lineHeight: "45px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.8",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1.5",
                            },
                        }}
                    >
                        How We <span style={{ color: theme.palette.primary.dark }}>
                            Help?
                        </span>
                    </Typography>

                    <Typography
                        variant="body1"
                        className="text"
                    >
                        We understand that travelling outside of your country for dental care maybe confusing and even overwhelming. Here is how Global Care Finder can help:
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "1.5em",
                            marginY: "10px",
                            lineHeight: "30px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.2em",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1em",
                            },
                        }}
                    >
                        Find good quality global clinicians
                    </Typography>

                    <Typography
                        variant="body1"
                        className="text"
                    >
                        Each clinic listed on the Global Care Finder website must complete a questionnaire pertaining to the doctors and their qualifications and licenses, as well as procedures adopted by the clinic toward safety and hygiene. Questions include years in business, doctor licensing, insurance, warranties and guarantees, criminal or malpractice history and sterilization protocols.

                        Patient testimonials and reviews are an important part of our quality measures – after all, no-one knows more about the quality of services than our patients. Global Care Finder publishes reviews written by patients who have had treatment at the clinics on our site. Ensuring these reviews are truthful, and an accurate reflection of each patient’s experience is imperative, which is why we publish all reviews – good, bad or indifferent – and take care to remove fake ones.

                        Furthermore, personally visiting clinics and meeting with both the doctors and the management teams plays a crucial part. We currently visit 80% of our clinics every year, but hope to increase this to 100% in the near future.
                    </Typography>
                </Box>
                <Box component="img" src={HelpImage}
                    sx={{
                        maxWidth: "500px",
                        maxHeight: "400px",
                        [theme.breakpoints.down("md")]: {
                            maxWidth: "300px",
                            maxHeight: "300px",
                            marginBottom: "10px"

                        },
                    }}

                />

            </Box>


            <Typography
                variant="h2"
                sx={{
                    fontSize: "1.5em",
                    marginY: "10px",
                    lineHeight: "30px",
                    fontWeight: "500",
                    [theme.breakpoints.down("md")]: {
                        fontSize: "1.2em",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "1em",
                    },
                }}
            >
                Save money
            </Typography>

            <Typography
                variant="body1"
                className="text"
            >
                Medical insurance doesn’t come cheap and in countries where there is public health care, waiting lists are long. In any case, feel-good cosmetic surgery treatments are rarely included in healthcare coverage. Most people can save at least 50%, amounting to thousands on even a relatively small cosmetic surgery treatment abroad, so travelling overseas for your treatment is kind to your pocket.

                If you are wondering why dental treatment is so much less abroad it is not because the quality is less, or the materials used lower grade. It is simply because the cost of living is lower and so materials, labour costs and other overheads such as office rent are tiny compared to in the West.
            </Typography>

            <Typography
                variant="h2"
                sx={{
                    fontSize: "1.5em",
                    marginY: "10px",
                    lineHeight: "30px",
                    fontWeight: "500",
                    [theme.breakpoints.down("md")]: {
                        fontSize: "1.2em",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "1em",
                    },
                }}
            >
                Save time
            </Typography>


            <Typography
                variant="body1"
                className="text"
            >
                Finding a good doctor or surgeon overseas is so much easier and quicker with Global Care Finder. No longer do you have to trawl the internet to search for global dental clinics, you can find, compare and book an appointment on our site from providers available across the globe.
            </Typography>


            <Typography
                variant="h2"
                sx={{
                    fontSize: "1.5em",
                    marginY: "10px",
                    lineHeight: "30px",
                    fontWeight: "500",
                    [theme.breakpoints.down("md")]: {
                        fontSize: "1.2em",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "1em",
                    },
                }}
            >
                Feel safe & secure
            </Typography>

            <Typography
                variant="body1"
                className="text"
                mb={1.5}
            >
                Global Care Finder takes your security seriously. We ensure your data is safe by storing your personal information in secure databases at secure addresses. Only authorized people at Global Care Finder have access to your data and we do not give or sell any of your information to any other sources. Your appointment data is protected and encrypted during transmission with Secure Socket Layer (SSL), and all our security protocols are certified by VeriSign.
            </Typography>
        </Box >
    )
}

export default HowWeHelp