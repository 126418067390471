import { ApiErrorMessages } from "constants";
import { toast } from "react-toastify";
import ApiClient from "./ApiClient";
import { logoutUser } from "store/users";

const postSignUp = (values) =>
  ApiClient.post("auth/patient/sign-up", values).catch((error) => {
    if (error?.response?.data?.statusCode === 409) {
      return error;
    }
    return toast.error(ApiErrorMessages.postSignUp);
  });

const deleteUser = (userId) =>
  ApiClient.delete(`users/${userId}`).catch(() => {
    toast.error(ApiErrorMessages.accountDeleted)
  });

const postBooking = (navigate, dispatch, values) =>
  ApiClient.post("/appointment", values).catch(() => {
    toast.error(ApiErrorMessages.accountDeleted)
    dispatch(logoutUser());
    navigate("/sign-in")
  }
  );

const getMyAppointments = (navigate, dispatch, take, pageNo) =>
  ApiClient.get(
    `/appointment/get-my-appointments?take=${take}&pageNo=${pageNo}`
  ).catch((err) => {
    toast.error(ApiErrorMessages.accountDeleted);
    dispatch(logoutUser());
    navigate("/sign-in")
  });

const getUserData = (navigate, dispatch, id) =>
  ApiClient.get(`/users/${id}`).catch(() => {
    toast.error(ApiErrorMessages.accountDeleted)
    dispatch(logoutUser());
    navigate("/sign-in")
  });

const updatePatientProfile = ({ userId, body }) =>
  ApiClient.patch(`users/patient/${userId}`, body).catch(() =>
    toast.error(ApiErrorMessages.updatePatientProfile)
  );

const getSignedUrl = (body) => ApiClient.post("users/getSignedUrl", body);

const addReview = (body) => ApiClient.post('reviews/add-review', body)

const forgotPassword = (body) => ApiClient.post('auth/forgot-password', body).catch(() =>
  toast.error(ApiErrorMessages.userNotFound));
const resetPassword = ({ token, newPassword }) => ApiClient.post('auth/reset-password', { token, newPassword });
const getScripts = () => ApiClient.get('/scripts/get-scripts');
const postContactUsData = (body) => ApiClient.post('/contact-us/add-contact-data', body);


const services = {
  postSignUp,
  postBooking,
  getMyAppointments,
  getUserData,
  updatePatientProfile,
  getSignedUrl,
  addReview,
  forgotPassword,
  resetPassword,
  deleteUser,
  getScripts,
  postContactUsData,
};

export default services;
