import { createSlice } from "@reduxjs/toolkit";
import { ApiSuccessMessages, RestMethods } from "constants";
import { toast } from "react-toastify";
import { getResponse } from "utils/get-response";
import { apiCallBegan } from "./actions";

const storageKey = "loggedUserInfo";
const accessToken = "accessToken";

const initialState = {
  list: [],
  pagination: {},
  usersLoader: false,
  authLoader: false,
  registerUserLoader: false,
  onlineUsersList: [],
  isLoginSuccess: false,
  isRegisterSuccess: false,
  loginErrorMessage: null,
  loggedUser: typeof window !== "undefined" ? JSON.parse(localStorage.getItem("yourStorageKey")) : null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    usersRequested: (state) => {
      state.usersLoader = true;
    },

    usersRequestFailed: (state, action) => {
      state.usersLoader = false;
    },

    usersReceived: (state, { payload }) => { },

    registerUser: (state, { payload }) => {
      payload = getResponse(payload);
      localStorage.setItem(storageKey, JSON.stringify(payload));
      state.loggedUser = payload;
      state.isLoginSuccess = true;
      state.isRegisterSuccess = true;
      toast.success(ApiSuccessMessages.registerUser);
    },

    logoutUser: (state, action) => {
      localStorage.removeItem(storageKey);
      localStorage.removeItem(accessToken);
      state.loggedUser = false;
      state.isLoginSuccess = false;
      toast.success(ApiSuccessMessages.logoutUser);
    },
    updateAvatar: (state, { payload }) => {
      state.loggedUser.user.avatar = payload;
    },

    signInRequested: (state, { payload }) => {
      state.loginErrorMessage = null;
    },

    signInSuccess: (state, { payload }) => {
      payload = getResponse(payload);
      localStorage.setItem(storageKey, JSON.stringify(payload));
      localStorage.setItem("accessToken", payload?.access_token);
      state.loggedUser = payload;
      state.isLoginSuccess = true;
      state.isRegisterSuccess = false;

      toast.success(ApiSuccessMessages.signInSuccess);
      state.loginErrorMessage = null;
    },

    signInFailed: (state, action) => {
      state.loginErrorMessage = action.payload.message;
    },
  },
});

export const {
  usersRequested,
  usersRequestFailed,
  usersReceived,
  registerUser,
  logoutUser,
  signInRequested,
  signInSuccess,
  signInFailed,
  updateAvatar,
} = usersSlice.actions;

export default usersSlice.reducer;

export const userLogin = (path, payload) => {
  return apiCallBegan({
    url: path,
    data: payload,
    method: RestMethods.POST,
    onStart: signInRequested.type,
    onSuccess: signInSuccess.type,
    onError: signInFailed.type,
  });
};
