import React, { useState, useEffect } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import useApi from 'hooks/useApi';
import { getResponse } from 'utils/get-response';
import DoctorService from "services/DoctorService";
import HalfRating from 'components/ratings/Ratings';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from "react-router-dom";
import LocationsSkeleton from '../skeletons/LocationsSkeleton';
import NoData from '../no-data/noData';
import { specificCountries } from 'constants/countries';
import NotFound from '../NotFound/NotFound';

const DoctorsByLocation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const [limit] = useState(10);
    const [totalCount, setTotalCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [doctors, setDoctors] = useState([]);
    const [countries, setCountries] = useState(specificCountries)
    const [countryName, setCountryName] = useState(null)
    const [notFound, setNotFound] = useState(false)

    const getDoctorsByCountryApi = useApi(
        DoctorService.getDoctorsByCountry
    );

    const fetchRelevantDoctors = async () => {
        const currentPath = location.pathname.startsWith("/")
            ? location.pathname.slice(11)
            : location.pathname;
        setCountryName(location.pathname.slice(11).replace(/%20/g, " "))
        const matches = countries.some(country => country.label === location.pathname.slice(11).replace(/%20/g, " "));

        if (currentPath && matches) {
            const query = `country=${currentPath}&take=${limit}&pageNo=${currentPage}`;

            let response = await getDoctorsByCountryApi.request(query);
            response = getResponse(response);
            if (response.doctors && response.totalCount > 0) {
                setDoctors(response.doctors)
                setTotalCount(response.totalCount)
                setLoading(false)
            } else {
                setTotalCount(0)
                setLoading(false)
            }
        } else {
            setNotFound(true)
        }
    };

    useEffect(() => {
        fetchRelevantDoctors(currentPage);
    }, [currentPage]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const getDoctorSpecialities = (doctor) => {
        const specialitiesNames = !doctor?.doctor
            ? doctor.specialities.map(
                (doctorSpeciality) => doctorSpeciality.specialityName
            )
            : doctor.doctor.doctorSpecialities.map(
                (doctorSpeciality) => doctorSpeciality.speciality.name
            );

        return specialitiesNames.join(",");
    };


    const theme = useTheme();

    return (
        notFound ?
            <NotFound />
            :
            <Box sx={{
                maxWidth: "1400px",
                margin: "auto",
                marginY: "30px",
                minHeight: '100vh'
            }}>
                {!loading
                    ?
                    totalCount > 0
                        ? <>
                            <Typography sx={{
                                fontSize: "2em",
                                marginBottom: "10px",
                                lineHeight: "45px",
                                fontWeight: "500",
                                [theme.breakpoints.down("md")]: {
                                    fontSize: "1.8",
                                },
                                [theme.breakpoints.down("sm")]: {
                                    fontSize: "1.5",
                                },
                            }} variant='h1'>Doctors in <span style={{ color: theme.palette.primary.dark }}>
                                    {countryName}
                                </span>
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "30px" }}>
                                <Box sx={{ display: "flex", gap: "20px", justifyContent: "space-between", flexWrap: "wrap", padding: "20px", }}>
                                    {doctors?.map((doctor, index) => (
                                        <Box
                                            onClick={() => navigate(`/doctor-profile/${doctor.id}`)}
                                            key={index}
                                            sx={{
                                                width: "45%",
                                                // minHeight: "250px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                display: "flex",
                                                gap: "15px",
                                                padding: "15px",
                                                backgroundColor: "white",
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                position: "relative",
                                                padding: "20px",
                                                "&::before": {
                                                    content: '""',
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "200px",
                                                    height: "100%",
                                                    backgroundColor: "#e5e5e5",
                                                    clipPath: "polygon(-150px 30px, 92.00% -71px, 33px 125.77%)",
                                                    zIndex: 1,
                                                },
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                                [theme.breakpoints.down("md")]: {
                                                    width: "100%",
                                                },
                                                [theme.breakpoints.down("sm")]: {
                                                    flexDirection: "column"
                                                },
                                            }}>
                                            <Box sx={{
                                                position: "relative",
                                                zIndex: 100
                                            }}>
                                                {doctor?.avatar ? (
                                                    <Avatar
                                                        alt={doctor?.firstName}
                                                        sx={{
                                                            width: 120,
                                                            height: 128,
                                                            borderRadius: "5px",


                                                        }}
                                                        src={doctor?.avatar}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: theme.palette.primary.main,
                                                            width: 120,
                                                            height: 128,
                                                            fontSize: "3em",
                                                            borderRadius: "5px",
                                                            color: theme.palette.background.paper,
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        {doctor?.firstName[0]}
                                                    </Avatar>
                                                )}
                                            </Box>
                                            <Box sx={{
                                                position: "relative",
                                                zIndex: 1000
                                            }}>
                                                <Typography variant='h1' sx={{ fontSize: 20, textTransform: "uppercase", marginBottom: "10px" }}>{doctor?.firstName + " " + doctor?.lastName}
                                                </Typography>

                                                <Box>
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "5px", alignItems: "center", marginBottom: "5px" }}>
                                                        <Typography sx={{ fontSize: 16 }} variant='h2'>Specialization: </Typography>
                                                        <Typography sx={{ fontSize: 16, fontWeight: "normal" }} variant='h3'>{getDoctorSpecialities(doctor)}</Typography>
                                                    </Box>

                                                    <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap", alignItems: "center", marginBottom: "5px" }}>
                                                        <Typography sx={{ fontSize: 16 }} variant='h2'>Rating: </Typography>

                                                        <HalfRating
                                                            value={doctor?.doctor?.aggregatedRating}
                                                            readOnly={true}
                                                        />
                                                    </Box>
                                                    {doctor?.doctor?.about &&
                                                        <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap", }}>
                                                            <Typography sx={{ fontSize: 16 }} variant='h2'>About: </Typography>
                                                            <Typography sx={{ fontSize: 16, fontWeight: "normal" }} variant='p'>{doctor?.doctor?.about && doctor?.doctor?.about.length > 120
                                                                ? doctor?.doctor?.about.slice(0, 120 - 1) + "........"
                                                                : doctor?.doctor?.about}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))
                                    }
                                </Box>
                                <Pagination
                                    sx={{ marginLeft: "auto" }}
                                    count={Math.ceil(totalCount / limit)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </Box>
                        </>
                        : <NoData isTable={false} name={"Doctors"} />
                    : <LocationsSkeleton />
                }
            </Box >
    )
}

export default DoctorsByLocation