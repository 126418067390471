import React, {
    useEffect,
    // useState
} from 'react';
import {
    Box,
    //  Tab
} from '@mui/material';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';

// import MedicalServicesTwoToneIcon from '@mui/icons-material/MedicalServicesTwoTone';
// import SchemaTwoToneIcon from '@mui/icons-material/SchemaTwoTone';
// import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
// import GCFIcon from 'assets/images/main/logo.png';
// import Diversity1TwoToneIcon from '@mui/icons-material/Diversity1TwoTone';

import OurCompany from 'components/ui/OurCompany/OurCompany';
import HowItWorks from 'components/ui/HowItWorks/HowItWorks';
import HowWeHelp from 'components/ui/HowWeHelp/HowWeHelp';
import WhyUseGCF from 'components/ui/WhyUseGCF/WhyUseGCF';
import OurCommunity from 'components/ui/OurCommunity/OurCommunity';

const AboutUs = () => {

    // const [value, setValue] = useState(0);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };


    // const iconPosition = "start";
    // const aboutUsTabs = [
    //     { value: 0, label: "Our Company", icon: <MedicalServicesTwoToneIcon /> },
    //     { value: 1, label: "How it works", icon: <SchemaTwoToneIcon /> },
    //     { value: 2, label: "How we help", icon: <HelpTwoToneIcon /> },
    //     { value: 3, label: "Why use Global Care Finder", icon: GCFIcon },
    //     { value: 4, label: "Our Community", icon: <Diversity1TwoToneIcon /> },
    // ]

    const componentsArray = [
        <OurCompany />,
        <HowItWorks />,
        <HowWeHelp />,
        <WhyUseGCF />,
        <OurCommunity />]

    return (
        <Box sx={{
            maxWidth: "1400px",
            margin: "auto",
            padding: { lg: "2em 1em", xs: "1em" }
        }}>
            {/* <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList variant="scrollable"
                        scrollButtons={false} onChange={handleChange} aria-label="About us Tabs">
                        {aboutUsTabs.map((tab, index) => (
                            <Tab iconPosition={iconPosition} key={tab.value} icon={tab.value === 3 ? <img src={tab.icon} alt={tab.label} style={{ width: 24, height: 24, filter: value !== 3 ? "grayscale(100%)" : "grayscale(0%)", }} /> : tab.icon} label={tab.label} />
                        ))}
                    </TabList>
                </Box>
                <TabPanel value={value}>
                    {componentsArray[value]}
                </TabPanel>
            </TabContext> */}
            {
                componentsArray.map((component, index) => (
                    component
                ))
            }
        </Box >
    )
}

export default AboutUs