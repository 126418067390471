import { Box, Typography, useTheme } from '@mui/material'
import React from 'react';
import GCFImage from "assets/images/main/logo.png";

const OurCompany = () => {
    const theme = useTheme()
    return (
        <Box>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                },
            }}>
                <Box>
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: "2em",
                            marginBottom: "10px",
                            lineHeight: "45px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.8",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1.5",
                            },
                        }}
                    >Our <span style={{ color: theme.palette.primary.dark }}>
                            Mantra
                        </span></Typography>
                    <Typography
                        variant="body1"
                        mb={1.5}
                        className="text"
                    >
                        We believe that it is the right of every individual to have access to affordable high quality medical care.
                        Medical care is expensive. We know by personal experience that patients can receive quality medical care at a fraction of the cost by travelling overseas. To achieve this goal, Global Care Finder will build the largest global dental marketplace: to bring customers together with high quality global dental providers.
                        We believe that we can provide medical services to the planet that will improve people's lives through greater transparency and quality. Global Care Finder will leverage disruptive ideas & technology to create real value for our customers.
                        We promise to focus relentlessly on you our customer: to deliver you high quality medical services at a reasonable price.
                        A world of medical care. For less.
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "1.5em",
                            marginBottom: "8px",
                            lineHeight: "30px",
                            fontWeight: "500",
                            [theme.breakpoints.down("md")]: {
                                fontSize: "1.2em",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "1em",
                            },
                        }}
                    >Get in touch</Typography>
                    <Typography
                        variant="body1"
                        className="text"
                    >
                        You can contact us at support@globalcarefinder.com
                    </Typography>
                </Box>
                <Box component="img" src={GCFImage}
                    sx={{
                        width: "300px",
                        height: "300px",
                        [theme.breakpoints.down("md")]: {
                            marginBottom: "10px"
                        },
                    }}

                />
            </Box>

        </Box>
    )
}

export default OurCompany