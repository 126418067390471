import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import BlogService from "services/BlogsService";

import useApi from "hooks/useApi";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import GlobalCareFinderMainImage from "assets/images/main/main-logo.png";

const Blog = () => {
  const theme = useTheme();
  const location = useLocation();
  const getBlogApi = useApi(BlogService.getBlogById);
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allParagraphs, setAllParagraphs] = useState([]);
  const [allImages, setAllImages] = useState([]);

  const fetchBlogContent = async () => {
    try {
      const id = location.pathname.startsWith("/")
        ? location.pathname.slice(6)
        : location.pathname;

      const blogData = await getBlogApi.request(id);

      if (blogData.data.statusCode === 200) {
        setLoading(false);
        setBlog(blogData.data.response);
      }
    } catch (error) {
      setLoading(false);
      setBlog(null);
      toast.error("Error getting signed URL for image upload.");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBlogContent();
  }, []);

  const getSlicedHeading = (content, maxLength = 30) => {
    const textContent = content.find((item) => item.type === "text");
    if (textContent) {
      return textContent.data.length > maxLength
        ? textContent.data.slice(0, maxLength) + "..."
        : textContent.data;
    }
    return "Global Care Finder Blogs";
  };

  const getMainImage = (content) => {
    const image = content.find((item) => item.type === "image");
    if (image) {
      return image.data;
    }
    return GlobalCareFinderMainImage;
  };

  const getAllParagraphs = (content, maxLength = 0) => {
    const textContent = content.filter(
      (item) => item.type === "text" && item.data.length > maxLength
    );
    if (textContent.length > 0) {
      setAllParagraphs(textContent);
    } else {
      setAllParagraphs([{ type: "text", data: "Global Care Finder Blogs" }]);
    }
  };

  const getAllImages = (content) => {
    const images = content.filter((item) => item.type === "image");
    if (images.length > 0) {
      const filteredImages = images.slice(1);
      setAllImages(filteredImages);
    } else {
      setAllImages([]);
    }
  };

  useEffect(() => {
    if (blog) {
      getAllParagraphs(blog.content);
      getAllImages(blog.content);
    }
  }, [blog]);


  return (
    <Box className="home-page">
      <Box sx={{ maxWidth: "1400px", margin: "auto", paddingY: "2em" }}>
        {blog && blog.content && (
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "5px", p: 2 }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: "30px",
                fontWeight: "normal",
                margin: ".5rem 0rem 1rem 0rem",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1.2em",
                },
              }}
            >
              {blog.title}
            </Typography>
            {blog.content.map((blogContent, index) => (
              blogContent.type === "image"
                ? <Box
                  component="img"
                  src={getMainImage(blog.content)}
                  sx={{
                    maxWidth: "600px",
                    height: "400px",
                    objectFit: "cover",
                    marginY: "10px"
                  }}
                />
                : blogContent.type === "text"
                  ? <Typography
                    key={index}
                    variant="p"
                    sx={{
                      fontWeight: "500",
                      display: "block",
                      marginBottom: "10px",
                      fontSize: "16px",
                    }}
                  >
                    {blogContent.data}
                  </Typography>
                  : blogContent.type === "h2"
                    ? <Typography
                      variant="h2"
                      sx={{
                        fontSize: "25px",
                        fontWeight: "normal",
                        margin: ".3rem 0rem .3rem 0rem",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "1em",
                        },
                      }}
                    >
                      {blogContent.data}
                    </Typography>
                    : null
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Blog;
