import { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';

const AnimatedBox = ({ children, animation = 'slide-in', delay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const boxRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  const animations = {
    'fade-up': {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
      transition: `all 0.6s ease-out ${delay}s`
    },
    'fade-in': {
      opacity: isVisible ? 1 : 0,
      transition: `opacity 0.6s ease-out ${delay}s`
    },
    'slide-in': {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'translateX(0)' : 'translateX(-100px)',
      transition: `all 0.8s cubic-bezier(0.16, 1, 0.3, 1) ${delay}s`,
      willChange: 'transform, opacity'
    },
    'scale-up': {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'scale(1)' : 'scale(0.8)',
      transition: `all 0.6s ease-out ${delay}s`
    }
  };

  return (
    <Box
      ref={boxRef}
      sx={{
        ...animations[animation],
        visibility: isVisible ? 'visible' : 'hidden'
      }}
    >
      {children}
    </Box>
  );
};

export default AnimatedBox;