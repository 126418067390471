
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import DoctorImage from "assets/images/users/Doctor.png";
import PatientImage from "assets/images/users/Patient.png";
import { PathName, ROLE, ROUTES } from "constants";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LogoSection from "../LogoSection";
import BrowseMoreModal from "./BrowseMoreModal";
import ProfileSection from "./ProfileSection";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import GCFLogo from "assets/images/main/main-logo.png";


import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";


const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[900],
  fontSize: "16px",
}));

const Header = () => {
  const theme = useTheme();

  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const currentUser = useSelector(
    (state) => state.entities.users.isLoginSuccess === true
  );
  const [browseMoreOpen, setBrowseMoreOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [current, setCurrent] = useState();

  const exploreMoreHandler = () => {
    setBrowseMoreOpen((prevState) => !prevState);
  };

  const navLinks = [
    { name: "About us", route: "/about-us", icon: <InfoOutlinedIcon /> },
    {
      name: "Contact us",
      route: "/contact-us",
      icon: <ContactsOutlinedIcon />,
    },
    { name: "Sitemap", route: "/sitemap", icon: <MapOutlinedIcon /> },
    {
      name: "Locations",
      route: "/locations",
      icon: <LocationOnOutlinedIcon />,
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = (value) => {
    setOpen(true);
    setValue(0);
    setCurrent(value);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrent("");
  };

  const reRouteUser = (user, path) => {
    if (path === PathName.SignUp) {
      if (user === ROLE.Doctor) {
        window.location.href = `${process.env.REACT_APP_ADMIN_PANEL}${ROUTES.SignUp}`;
      } else {
        navigate(ROUTES.SignUp);
      }
    } else if (path === PathName.SignIn) {
      if (user === ROLE.Doctor) {
        window.location.href = `${process.env.REACT_APP_ADMIN_PANEL}${ROUTES.SignIn}`;
      } else {
        navigate(ROUTES.SignIn);
      }
    }
  };

  return (
    <Box
      className="navbar"
      sx={{ backgroundColor: theme.palette.primary.light }}
    >
      <Box className="navbar-section">
        {/* Logo */}
        <Box
          component="img"
          src={GCFLogo}
          onClick={() => navigate(ROUTES.Home)}
          sx={{ maxWidth: "150px", maxHeight: "42.5px", cursor: "pointer" }}
        />

        {/* Navigation */}

        <Box sx={{ display: { xs: "none", md: "flex" }, gap: "15px" }}>

          {navLinks.map((link, index) => {
            const isActive = location.pathname === link.route;
            return (
              <StyledButton
                key={index}
                sx={{
                  color: isActive
                    ? theme.palette.primary[800]
                    : theme.palette.grey[900],
                  paddingX: "8px",
                  borderRadius: "27px",
                }}
                onClick={() => navigate(link.route)}
              >
                {link.name}
              </StyledButton>
            );
          })}

          {currentUser ? (
            <Box
              sx={{
                borderLeft: `1px solid ${theme.palette.grey[500]}`,
                marginLeft: "15px",
                paddingLeft: "15px",
              }}
            >
              <ProfileSection navLinks={navLinks} />
            </Box>
          ) : (
            <Box
              sx={{
                borderLeft: `1px solid ${theme.palette.grey[500]}`,
                marginLeft: "15px",
                paddingLeft: "15px",
                display: "flex",
                gap: "15px",
              }}
            >
              <StyledButton
                sx={{ width: "100px" }}
                onClick={() => handleOpen("login")}
              >
                Login
              </StyledButton>

              <StyledButton
                variant="contained"
                sx={{ width: "100px", color: theme.palette.grey[50] }}
                onClick={() => handleOpen("signup")}
              >
                Sign up
              </StyledButton>
            </Box>
          )}

        </Box>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              width: 280,
              mt: 2,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={{
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          }}
        >
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              padding: "1em 2em",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                borderRadius: "10px",
              },
            }}
            onClick={() => handleOpen("login")}
          >
            <LoginIcon color="primary" />
            <Typography variant="span">Login</Typography>
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              padding: "1em 2em",
            }}
            onClick={() => handleOpen("signup")}
          >
            <PersonAddIcon color="primary" />
            <Typography variant="span">Register</Typography>
          </MenuItem>

          {navLinks.map((link, index) => {
            const isActive = location.pathname === link.route;
            return (
              <MenuItem
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1em",
                  padding: "1em 2em",
                  color: isActive
                    ? theme.palette.primary[800]
                    : theme.palette.grey[900],
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    borderRadius: "10px",
                  },
                }}
                onClick={() => {
                  handleCloseMenu()
                  navigate(link.route)
                }}
              >
                {link.icon}
                <Typography variant="span">{link.name}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "10px",
            [theme.breakpoints.down("sm")]: {
              width: 300,
            },
          }}
        >
          <Tabs
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
            sx={{
              "& .MuiButtonBase-root.MuiTab-root": {
                width: "50%",
                padding: "20px 16px",
              },
            }}
          >
            <Tab label="Patient" />
            <Tab label="Doctor" />
          </Tabs>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1em",
              flexWrap: "wrap",
            }}
          >
            {value === 0 ? (
              <Box
                sx={{
                  width: "300px",
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "0.7em",
                  }}
                >
                  <img
                    src={PatientImage}
                    style={{ width: "90px", height: "90px" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ marginTop: ".5rem", color: theme.palette.grey[600] }}
                  >
                    {current === "login"
                      ? "Log in "
                      : current === "signup"
                        ? "Register "
                        : null}
                    to view / book appointments and doctors. Communicate with
                    your doctor and schedule your appointments.
                  </Typography>

                  {current === "login" ? (
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => reRouteUser(ROLE.Patient, PathName.SignIn)}
                    >
                      Login
                    </Button>
                  ) : current === "signup" ? (
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => reRouteUser(ROLE.Patient, PathName.SignUp)}
                    >
                      Register
                    </Button>
                  ) : null}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "300px",
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "0.7em",
                  }}
                >
                  <img
                    src={DoctorImage}
                    style={{ width: "90px", height: "90px" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ marginTop: ".5rem", color: theme.palette.grey[600] }}
                  >
                    {current === "login"
                      ? "Log in "
                      : current === "signup"
                        ? "Register "
                        : null}
                    to view all appointments and patients. Communicate with your
                    patients and schedule your availability.
                  </Typography>
                  {current === "login" ? (
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => reRouteUser(ROLE.Doctor, PathName.SignIn)}
                    >
                      Login
                    </Button>
                  ) : current === "signup" ? (
                    <Button
                      variant="contained"
                      sx={{ width: "100%" }}
                      onClick={() => reRouteUser(ROLE.Doctor, PathName.SignUp)}
                    >
                      Register
                    </Button>
                  ) : null}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box >
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
