import { toast } from "react-toastify";
import ApiClient from "./ApiClient";

const getAllBlogs = (paginationOptions) => ApiClient.get(`blogs/get-blogs?${paginationOptions}`)
  .catch(() => toast.error("Unable to get the blogs"));;

const getBlogById = (id) => ApiClient.get(`blogs/${id}`);

const getLatestBlogs = (value) => ApiClient.get(`blogs/get-latest-blogs/?take=4&&pageNo=1`)
  .catch(() => toast.error("Unable to get the blogs"));


const services = {
  getAllBlogs,
  getBlogById,
  getLatestBlogs
};

export default services;
