import React from "react";
import MainLayout from "components/layout/MainLayout";
import Loadable from "components/ui/Loadable";
import { ROUTES } from "constants";
import { lazy } from "react";
import TermsCondition from "views/public/terms-condition/TermsCondition";
import PrivacyPolicy from "views/public/privacy-policy/PrivacyPolicy";
import HtmlSitemap from "components/htmlSitemap/HtmlSitemap";
import NotFound from "components/ui/NotFound/NotFound";
import ThankYou from "views/private/thank-you/ThankYou";
import AboutUs from "views/public/about-us/AboutUs";
import ContactUs from "views/public/contact-us/ContactUs";
import Locations from "views/public/locations/Locations";
import Blog from "components/ui/Blog/Blog";
import AllBlogs from "components/ui/AllBlogs/AllBlogs";

const DashboardDefault = Loadable(
  lazy(() => import("views/private/dashboard"))
);
const DoctorProfilePage = Loadable(lazy(() => import("views/public/profile")));
const SearchDoctorPage = Loadable(lazy(() => import("views/public/search")));
const ManageProfile = Loadable(
  lazy(() => import("views/private/manage-profile"))
);
const ReviewAndBook = Loadable(lazy(() => import("views/public/booking")));

const MainRoutes = {

  path: ROUTES.Home,
  element: <MainLayout />,

  children: [
    {
      path: ROUTES.Home,
      element: <DashboardDefault />,
    },
    {
      path: ROUTES.DoctorSearch,
      element: <SearchDoctorPage />,
    },
    {
      path: ROUTES.Location,
      element: <Locations />,
    },
    {
      path: ROUTES.DynamicRoute,
      element: <SearchDoctorPage />,
    },
    {
      path: ROUTES.DoctorProfile,
      element: <DoctorProfilePage />,
    },
    {
      path: ROUTES.ManageProfile,
      element: <ManageProfile />,
    },
    {
      path: ROUTES.DoctorBooking,
      element: <ReviewAndBook />,
    },
    {
      path: ROUTES.TermsCondition,
      element: <TermsCondition />
    },
    {
      path: ROUTES.PrivacyPolicy,
      element: <PrivacyPolicy />
    },
    {
      path: ROUTES.Sitemap,
      element: <HtmlSitemap />
    },
    {
      path: ROUTES.ThankYou,
      element: <ThankYou />
    },
    {
      path: ROUTES.AboutUs,
      element: <AboutUs />
    },
    {
      path: ROUTES.ContactUs,
      element: <ContactUs />
    },
    {
      path: ROUTES.Blog,
      element: <Blog />
    },
    {
      path: ROUTES.Blogs,
      element: <AllBlogs />
    },
    {
      path: "*",
      element: <NotFound />
    },
  ],
};

export default MainRoutes;
